<template>
  <div>
    確定結帳
  </div>
</template>

<script>
export default {};
</script>

<style></style>
